module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter Blog","short_name":"Gatsby","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"content/assets/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/opt/build/repo/gatsby-theme/src/utils/typography"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../gatsby-theme/gatsby-browser.js'),
      options: {"plugins":[],"categoryNameForAll":"all","paginationPageSize":5,"contentPath":"/opt/build/repo/site/content","assetPath":"/opt/build/repo/site/content/assets","commentsEnabled":true,"commentsProps":{"repo":"lucernae/maulana.id","repoId":"MDEwOlJlcG9zaXRvcnkyODI4NTE0MDM=","category":"Announcements","categoryId":"DIC_kwDOENv4S84CaVCv","mapping":"url","strict":"0","reactionsEnabled":"1","emitMetadata":"1","inputPosition":"top","theme":"preferred_color_scheme","lang":"en","loading":"lazy"},"algoliaProps":{"indexName":"Pages_Production"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1F37WJ9JDF"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"},"gtagConfig":{}},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
